define("ember-infinity/templates/components/infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y3/uN9/P",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"isVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[[23,0,[\"loaderClassNames\"]],[28,\"if\",[[23,0,[\"viewportEntered\"]],\" in-viewport\"],null],[28,\"if\",[[23,0,[\"isDoneLoading\"]],\" reached-infinity\"],null]]]],[12,\"data-test-infinity-loader\",\"\"],[3,\"did-insert\",[[23,0,[\"didInsertLoader\"]],[23,0,[]]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,0,[\"infinityModelContent\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"isDoneLoading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[8],[1,[23,0,[\"loadedText\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"span\",true],[8],[1,[23,0,[\"loadingText\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-infinity/templates/components/infinity-loader.hbs"
    }
  });

  _exports.default = _default;
});