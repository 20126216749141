define("@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (or (is-array @values) (eq "select" @controlType))}}
    {{#if (has-block)}}
      <Quill::Toolbar::Select @type={{@type}} ...attributes>
        {{yield}}
      </Quill::Toolbar::Select>
    {{else}}
      <Quill::Toolbar::Select
        @selected={{@selected}}
        @type={{@type}}
        @values={{@values}}
        ...attributes
      />
    {{/if}}
  {{else}}
    {{#if (has-block)}}
      <Quill::Toolbar::Button @type={{@type}} ...attributes>
        {{yield}}
      </Quill::Toolbar::Button>
    {{else}}
      <Quill::Toolbar::Button @type={{@type}} ...attributes />
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "ATcOh7yM",
    "block": "{\"symbols\":[\"@type\",\"&attrs\",\"&default\",\"@selected\",\"@values\",\"@controlType\"],\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"is-array\",[[23,5,[]]],null],[28,\"eq\",[\"select\",[23,6,[]]],null]],null]],null,{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"    \"],[5,\"quill/toolbar/select\",[[13,2]],[[\"@type\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n      \"],[14,3],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"quill/toolbar/select\",[[13,2]],[[\"@selected\",\"@type\",\"@values\"],[[23,4,[]],[23,1,[]],[23,5,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"    \"],[5,\"quill/toolbar/button\",[[13,2]],[[\"@type\"],[[23,1,[]]]],{\"statements\":[[0,\"\\n      \"],[14,3],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"quill/toolbar/button\",[[13,2]],[[\"@type\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});